import axios from 'axios';
import { Toast } from "vant";
import { Decrypt } from "@/scripts/utils";

const timeout = 10000;

// 添加请求拦截器
axios.interceptors.request.use((request: any) => {
    request.headers["Access-Token"] = localStorage.getItem("Token") ? Decrypt(localStorage.getItem("Token")) : "";
    return request;
});


axios.interceptors.response.use(
    (response: any) => {
        const { status, data } = response;
        if (status >= 200 && status < 300 || status == 304) {
            if (data.ErrCode === "Unauthorized") {
                localStorage.clear();
                window.location.href = '/login';
                Toast.fail("token过期，请重新登录！");
            }
            return data;
            // if (data.Result) {
            //     return data;
            // } else {
            //     if (data.ErrCode === "Unauthorized") {
            //         localStorage.clear();
            //         window.location.href = '/login';
            //         Toast.fail("token过期，请重新登录！");
            //     } else {
            //         if (data.Info && data.Info.length) {
            //             Toast.fail(data.Info[0]);
            //         } else {
            //             Toast.fail("操作失败了");
            //         }
            //     }
            // }
        } else {
            Toast.fail("操作失败了,请重新尝试！");
            throw response;
        }
    },
    (error: any) => {
        Toast.fail("操作失败了,请重新尝试！");
        throw error;
    },
);


/**
 * POST提交
 * @param {string} url           地址路径
 * @param {string} data          提交的数据
 * @param {string} contentType   Content-Type 默认值为：application/json; charset=UTF-8   上传文件的时候请用multipart/form-data
 * @param {string} baseURL       基础地址路径
 * @param {string} ex            扩展值
 * @param {string} outTime       超时时间
 * @param {function} onUploadProgress 上传处理进度事件
 */
export async function post<T>(url: string, data: T, ex = '', contentType = 'application/json; charset=UTF-8', baseURL = '', outTime = timeout, onUploadProgress?: ((progressEvent: any) => void) | undefined) {
    if (!baseURL) {
        if ((window as any).Config) {
            baseURL = `${(window as any).Config.UseSSL ? "https" : "http"}://${(window as any).Config.Host}/`
        }
    }

    return axios({
        method: "post",
        baseURL: baseURL,
        timeout: outTime,
        url,
        data: data,
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": contentType,
            "Ex-": ex
        },
        onUploadProgress
    })
}

export async function RTMPost<T>(url: string, data: T, ex = '', contentType = 'application/json; charset=UTF-8', rtmBaseURL = '', outTime = timeout, onUploadProgress?: ((progressEvent: any) => void) | undefined) {
    if (!rtmBaseURL) {
        rtmBaseURL = `${(window as any).Config.UseSSL ? "https" : "http"}://${(window as any).Config.Host}/FJRH.RTM/`;
    }
    post(url, data, ex, contentType, rtmBaseURL, outTime, onUploadProgress);
}

/**
 * GET 获取数据
 * @param {string} url          地址路径
 * @param {string} data         提交的数据
 * @param {string} baseURL      基础地址路径
 * @param {string} responseType 表示服务器将响应的数据类型
 * @param {string} timeoutTime  超时时间
 */
export async function get(url: string, params: any, baseURL = '', responseType: any = "json", outTime = timeout) {
    if (!baseURL) {
        if ((window as any).Config) {
            baseURL = `${(window as any).Config.UseSSL ? "https" : "http"}://${(window as any).Config.Host}/`
        }
    }
    return axios({
        method: 'get',
        baseURL: baseURL,
        timeout: outTime,
        responseType: responseType,
        url,
        ...params ? { params: params } : {}, // get 请求时带的参数
        headers: {
            'Accept': 'application/json',
        },
    })
}

export async function RTMGet(url: string, params: any, rtmBaseURL = '', responseType: any = "json", outTime = timeout) {
    if (!rtmBaseURL) {
        rtmBaseURL = `${(window as any).Config.UseSSL ? "https" : "http"}://${(window as any).Config.Host}/FJRH.RTM/`;
    }
    get(url, params, rtmBaseURL, responseType, outTime);
}
